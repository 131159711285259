import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import BreadcrumbList from "../components/seo/BreadcrumbList";
import useHubspotForm from "../hooks/useHubspotForm";

const Partner = () => {
    const { t } = useTranslation();

    const ref = useHubspotForm("a24b86ae-1872-4a19-90f6-38e6343f7e05");

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />

            <Container ref={ref} />
            <BreadcrumbList page="partner" />
        </Layout>
    );
};

const Container = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 3rem auto 3rem auto;
`;

export default Partner;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "homeHeader", "page.partner"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
