import { useEffect, useRef } from "react";

const useHubspotForm = (formId) => {
    const executed = useRef(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const elem = elementRef.current;
        if (!elem) return;
        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/v2.js";
        script.onload = function () {
            if (!window.hbspt || executed.current) return;
            executed.current = true;
            const script2 = document.createElement("script");
            script2.innerHTML = `window.hbspt.forms.create({
                region: "na1",
                portalId: "20242939",
                formId: "${formId}",
            });`;
            elem.appendChild(script2);
        };
        elem.appendChild(script);
    }, [formId]);

    return elementRef;
};
export default useHubspotForm;
